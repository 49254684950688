<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <div class="breadcrumb-artist-label">
      <v-avatar v-if="artistAvatarUrl" class="breadcrumb-artist-label__avatar" size="32">
        <v-img :src="artistAvatarUrl" />
      </v-avatar>
      <h2 class="breadcrumb-artist-label__text" :title="artistName">{{artistName}}</h2>
    </div>

    <v-spacer />

    <v-tooltip bottom v-if="currentBoxOffice.id">
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" min-width="0" v-on="on" text :to="'/dashboard/Events/' + currentBoxOffice.id">
          <v-icon>mdi-calendar-month</v-icon>
        </v-btn>
      </template>
      Switch Event
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="ml-2" min-width="0" v-on="on" text to="/dashboard/BoxOffices">
          <v-icon>mdi-ticket-account</v-icon>
        </v-btn>
      </template>
      Switch Box Office
    </v-tooltip>
 
    <v-menu bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <app-bar-item to="/Dashboard/Profile">
          <v-list-item-title>Profile</v-list-item-title>
        </app-bar-item>
        <app-bar-item>
          <v-list-item-title @click="logout()">Logout</v-list-item-title>
        </app-bar-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
  import { VHover, VListItem } from 'vuetify/lib'
  import { mapState, mapMutations } from 'vuex'
  import Store from '@/services/Store.js';
  import Utils from '@/services/Utils.js';

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      currentBoxOffice: Store.boxOffice,
    }),

    computed: {
      ...mapState(['drawer']),
      artistName() {
        return this.$store.state.auth.userAuth.full_name
      },
      artistAvatarUrl() {
        return this.$store.state.auth.userAuth.avatar_url
      }
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      logout() {
        Utils.logout(this, location.pathname)
      },

      home() {
        window.location = "/";
      },

    },

  }
</script>

<style lang="scss" scoped>
.breadcrumb-artist-label {
  display: flex;
  align-items: center;
  &__avatar {
    margin-right: 10px;
  }
  &__text {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 1em;
    line-clamp: 2;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    text-transform: none;
    font-family: Oxygen, sans-serif !important;
    // prevents overflow
    min-width: 0;
    font-size: 16px;
    font-weight: 500;
  }
}

.v-menu__content .v-list--nav .v-list-item {
  padding: 0;
  margin: 0;
}

.v-menu__content .v-list--nav .v-list-item .v-list-item__title {
    padding: 10px 20px;
}
</style>